// Import site utilities.
@import '../../global/utils/init';

.block-system-main-menu {

  .menu {

    ul {

      ul {
        background: transparent;
      }
    }

    li {

      &:hover {

        > a {
          color: var(--color-pacific-blue);
        }
      }

      > ul {
        display: none !important;
      }

      &.open {

        > a {
          color: var(--color-pacific-blue);
        }

        > ul {
          display: block !important;
        }
      }
    }

    > li {
      filter: none !important;
      padding: 12px 0;

      a {
        background: transparent;
        color: var(--color-black);
        cursor: pointer;
        font-family: var(--font-avenir);
        font-size: 13px;
        line-height: 20px;
        padding: 5px 10px 5px 25px;
        position: relative;
        white-space: inherit;

        .sub-arrow {
          border: 0;
          bottom: 0;
          height: auto;
          margin: 0;
          top: 0;
          transition: 0.5s;
          width: 20px;

          &::before {
            content: '\f105';
            display: block;
            font-family: var(--font-fontawesome);
            line-height: 20px;
            padding: 5px;
          }
        }

        &:hover,
        &:focus {
          color: var(--color-pacific-blue);
          line-height: 20px;
        }
      }

      > a {
        border-right: 1px solid var(--color-gray);
        font-size: 16px;
        font-weight: 900;
        padding: 0 12px;

        .sub-arrow {
          display: none;
        }

        &:hover,
        &:focus {
          background: transparent !important;
          font-weight: 900;
          line-height: 20px;
          padding: 0 12px;
        }
      }

      &:hover,
      &:focus {
        background: var(--color-menu-bg);

        > a {
          color: var(--color-pacific-blue);
        }
      }

      &:last-child {

        a {
          border: 0;
        }
      }

      > ul {
        background: linear-gradient(90deg, var(--color-foam-medium) 0%, var(--color-foam-medium) 50%, var(--color-french-pass) 50%, var(--color-french-pass) 100%);
        border-top: 8px solid var(--color-bermuda);
        box-shadow: 0 3px 6px var(--color-box-shadow);
        display: none !important;
        left: calc(50% - 220px) !important;
        max-width: unset !important;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 15px 0;
        position: fixed;
        top: 112px !important;
        transition: 0.5s;
        width: 440px !important;

        li {
          background: transparent;
          position: initial;
          width: 220px;

          a {
            padding: 5px 25px 5px 15px;

            &:hover {
              padding: 5px 25px 5px 15px;
            }
          }
        }

        > .menu-inner-wrapper {
          min-height: 160px;
          position: relative;

          > li {

            ul {
              left: 220px !important;
              margin: 0 !important;
              max-width: unset !important;
              min-height: 100%;
              min-width: unset !important;
              opacity: 1 !important;
              top: 0 !important;
              width: 220px !important;
            }
          }
        }
      }

      &.open {
        background: var(--color-menu-bg);

        > a {
          color: var(--color-pacific-blue);
        }

        &::after {
          bottom: 2px;
          color: var(--color-bermuda);
          content: '\f0d8';
          display: block;
          font-family: var(--font-fontawesome);
          font-size: 30px;
          left: calc(50% - 9px);
          line-height: 8px;
          margin: 0;
          padding: 0;
          position: absolute;
        }

        > ul {
          display: block !important;
        }
      }

      &:nth-child(1) {

        > ul {
          background: linear-gradient(90deg, var(--color-bubbles) 0%, var(--color-bubbles) 25%, var(--color-foam) 25%, var(--color-foam) 50%, var(--color-foam-medium) 50%, var(--color-foam-medium) 75%, var(--color-french-pass) 75%, var(--color-french-pass) 100%);
          left: calc(50% - 440px) !important;
          width: 880px !important;

          > .menu-inner-wrapper {
            min-height: 390px;
          }
        }
      }

      &:nth-child(2) {

        > ul {
          background: linear-gradient(90deg, var(--color-foam) 0%, var(--color-foam) 33.333333%, var(--color-foam-medium) 33.333333%, var(--color-foam-medium) 66.666666%, var(--color-french-pass) 66.666666%, var(--color-french-pass) 100%);
          left: calc(50% - 330px) !important;
          width: 660px !important;

          > .menu-inner-wrapper {
            min-height: 340px;
          }
        }
      }

      &:nth-child(4) {

        > ul {
          background: var(--color-french-pass);
          left: calc(50% + 45px) !important;
          width: 220px !important;
        }
      }
    }
  }
}

.admin-bar {

  .l-header {

    .block-system-main-menu {

      .menu {

        > li {

          > ul {
            top: 145px !important;
          }
        }
      }
    }
  }

  .scrolled {

    .l-header {

      .block-system-main-menu {

        .menu {

          > li {

            > ul {
              top: 112px !important;
            }
          }
        }
      }
    }
  }
}

@include md {

  .block-system-main-menu {
    
    .menu {
      background: var(--color-white);
      box-shadow: 0 3px 6px var(--color-box-shadow);
      left: 0;
      height: calc(100vh - 95px);
      width: 310px;
      min-height: calc(100vh - 95px);
      position: absolute;
      overflow-y: scroll;
      z-index: -1;

      > li {
        display: block !important;
        padding: 5px 20px;
        width: 100%;

        a {
          font-size: 16px;
          line-height: 26px;

          .sub-arrow {
            right: 17px;
          }
        }

        .menu-inner-wrapper {

          li {

            &.open {

              > a {

                .sub-arrow {

                  &::before {
                    rotate: 90deg;
                  }
                }
              }
            }
          }
        }

        > ul {

          li {
            width: 100%;

            ul {

              li {

                a {
                  color: var(--color-pacific-blue);
                }
              }
            }

            a {
              padding: 5px 25px !important;

              &:hover {
                background: var(--color-menu-bg);
              }
            }
          }

          > .menu-inner-wrapper {

            > li {

              ul {
                width: 100% !important;
                padding: 0 10px;
              }
            }
          }
        }

        > a {
          border-right: 0;
          padding: 7px 0;
          position: relative;

          &::after {
            bottom: 15px;
            content: '\f0da';
            display: block;
            font-family: var(--font-fontawesome);
            font-size: 20px;
            line-height: 8px;
            margin: 0;
            right: 0;
            padding: 0;
            position: absolute;
          }

          &::before {
            content: '';
            position: absolute;
            top: -6px;
            left: 0;
            width: 100%;
            border-top: 1px solid var(--color-silver);
          }

          &:hover,
          &:focus {
            padding: 10px 0;
          }
        }

        &.open {
          padding: 0;

          > a {
            border-bottom: 2px solid var(--color-bermuda);
            border-top: 2px solid var(--color-bermuda);
            margin: 0 20px;
            padding: 15px 0;

            &::after {
              bottom: 20px;
              rotate: 90deg;
              right: 5px;
            }

            &::before {
              border-top: 0;
            }
          }
  
          &::after {
            display: none;
          }
        }

        &:first-child {
          
          > a {

            &::before {
              border-top: 0;
            }
          }
        }
        
        &:nth-child(1) {

          > ul {
            background: var(--color-white);
            border-top: 0;
            box-shadow: none;
            left: 0 !important;
            top: 0 !important;
            position: relative;
            width: 100% !important;
  
            > .menu-inner-wrapper {
              min-height: auto;
            }
          }
        }

        &:nth-child(2) {

          > ul {
            background: var(--color-white);
            border-top: 0;
            box-shadow: none;
            left: 0 !important;
            top: 0 !important;
            position: relative;
            width: 100% !important;
  
            > .menu-inner-wrapper {
              min-height: auto;
            }
          }
        }

        &:nth-child(3) {

          > ul {
            background: var(--color-white);
            border-top: 0;
            box-shadow: none;
            left: 0 !important;
            top: 0 !important;
            position: relative;
            width: 100% !important;
          }

          .menu-inner-wrapper {
            min-height: auto;
          }
        }

        &:nth-child(4) {

          > ul {
            background: var(--color-white);
            border-top: 0;
            box-shadow: none;
            left: 0 !important;
            top: 0 !important;
            position: relative;
            width: 100% !important;
          }
        }
      }
    }
  }

  .admin-bar {

    .l-header {
  
      .block-system-main-menu {
  
        .menu {
          left: -14px;
  
          > li {
  
            > ul {
              top: 0 !important;
            }
          }
        }
      }
    }
  
    .scrolled {
  
      .l-header {
  
        .block-system-main-menu {
  
          .menu {
  
            > li {
  
              > ul {
                top: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
