// Import site utilities.
@import '../../global/utils/init';

.node-type-transplant-centers {

  .l-content {

    .node-transplant-centers {

      footer {
        display: none;
      }
    }
  }
}
