// Import site utilities.
@import '../../global/utils/init';

.l-top {
  background: var(--color-pacific-blue);
  padding: 90px 0;

  .page-title {
    color: var(--color-white);
    font-family: var(--font-archer);
    font-size: 58px;
    font-weight: 600;
    line-height: 58px;
    margin: 0;
    text-align: center;
  }
}

.front {

  .l-top {
    background: transparent;
    padding: 0;
  }
}

.node-type-news,
.node-type-event,
.node-type-transplant-centers {

  .l-top:has(> .block-block-empty-page-title) {
    padding: 40px 0;
  }

  .l-wrapper-inner {

    .block--inner-wrapper {

      .page-title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0;
      }
    }
  }
}

@include md {

  .l-top {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 45px 20px;
    min-height: 160px;
  
    .page-title {
      font-size: 32px;
      line-height: 32px;
    }
  }

  .node-type-news,
  .node-type-event,
  .node-type-transplant-centers {

    .l-wrapper-inner {

      .block--inner-wrapper {

        .page-title {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
  }
}
