// Import site utilities.
@import '../../global/utils/init';

.user-authentication-page {

  .view-sign-a-new-membership-agreement {

    .view-header {
      padding: 0;
    }

    .view-content {

      &::before {
        content: 'Previously signed membership agreements';
        color: var(--color-black);
        font-family: var(--font-avenir);
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.node-type-membership-agreement {

  .l-wrapper-inner {

    .container {
      max-width: 80vw;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
    }
  }

  .download-pdf {
    margin-top: 30px;
    text-align: right;

    a {
      font-size: 22px;

      i {
        font-family: var(--font-fontawesome);
      }
    }
  }
}

.node-membership-agreement {

  .member-aggrement-top {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    max-height: 300px;

    .member-aggrement-top-logo {
      aspect-ratio: 16/9;
      max-width: 50%;
      object-fit: cover;
    }

    .member-aggrement-top-text {

      p {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        text-align: end;
      }
    }
  }

  .membership-between {
    font-size: 36px;
    margin-bottom: 50px;
    text-align: center;
  }

  .facility-name {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 50px;

    .facility-text {
      flex: 1;
      text-align: end;
    }
    
    .membership-title {
      flex: 1;
      border-bottom: 2px solid;
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }
  }

  .cms-certification {

    .field {
      display: flex;
      justify-content: center;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 20px;

      .field-label {
        font-weight: normal;
        flex: 1;
        text-align: end;
        margin-right: 50px;
      }

      .field-items {
        flex: 1;

        .field-item {
          min-width: 300px;
          border-bottom: 2px solid;
          text-align: center;

          p {
            text-align: center;
          }

          a {
            text-decoration: none;
            color: var(--color-black);
          }
        }
      }
    }
  }

  .field-name-body {

    .field-item {
      margin: 80px 0;
      font-size: 32px;
      line-height: 42px;

      .center-align {
        text-align: center;
        margin-bottom: 0;
        margin-top: 50px;
      }

      .left-align {
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }

  .member-representative-signature,
  .mkn_representative {
    display: flex;
    // gap: 30px;
    margin-bottom: 30px;

    .field {
      font-size: 32px;
      text-align: center;
      line-height: 42px;

      .field-label {
        font-weight: normal;
      }

      .field-items {

        .field-item {
          min-width: 300px;
          border-bottom: 2px solid;
        }
      }
    }

    .member-signature {

      .field-items {

        .field-item {
          font-family: var(--font-signature);
          font-size: 42px;
        }
      }
    }
  }
}

// @include lg {

//   .node-membership-agreement {

//     .member-aggrement-top {
//       flex-direction: column;
//       margin-bottom: 50px;
//       min-height: 363px;
//       margin-top: 50px;

//       .member-aggrement-top-logo {
//         max-width: 100%;
//       }
//     }

//     .membership-between {
//       font-size: 24px;
//     }

//     .facility-name {
//       font-size: 18px;
//       line-height: 24px;

//       .facility-text {
//         flex: none;
//       }
//     }

//     .cms-certification {

//       .field {
//         display: block;
//         font-size: 18px;
//         line-height: 24px;

//         .field-label {
//           margin-right: 0;
//         }
//       }
//     }

//     .field-name-body {

//       .field-item {
//         margin: 50px 0;
//         font-size: 18px;
//         line-height: 24px;
//       }
//     }

//     .member-representative-signature,
//     .mkn_representative {
//       flex-direction: column;
//       gap: 0;
//       margin-bottom: 20px;

//       .field {
//         font-size: 18px;
//         line-height: 24px;
//       }
//     }
//   }
// }
