// Import site utilities.
@import '../../global/utils/init';

.paragraphs-item-sub-title {

  .field-item {
    font-family: var(--font-avenir);
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 28px;
    opacity: 1;
    text-align: left;
  }
}

@include md {

  .paragraphs-item-sub-title {

    .field-item {
      font-size: 17px;
      line-height: 24px;
    }
  }
}
