// Import site utilities.
@import '../../global/utils/init';

.block-search-form {

  .mobile-search-icon {
    line-height: 35px;
    display: none;

    &::after {
      color: var(--color-mercury);
      content: '\f002';
      display: contents;
      font-family: var(--font-fontawesome);
      font-size: 35px;
      font-weight: 600;
    }
  }

  .search-block-form {
    padding-bottom: 20px;

    > div {
      display: flex;
    }
  }

  .form-item {
    margin: 0;

    label {
      display: none;
    }

    input {
      background: var(--color-mercury);
      border: 0;
      color: var(--color-dove-gray);
      display: block;
      font-family: var(--font-avenir);
      font-size: 15px;
      padding: 4px 16px 2px;
      min-width: 165px;

      &::placeholder {
        color: var(--color-dove-gray);
        font-family: var(--font-avenir);
        text-transform: uppercase;
      }
    }
  }

  .form-actions {
    display: flex;
    margin: 0;
    position: relative;

    input {
      background: var(--color-white);
      border-radius: 0;
      border: 1px solid var(--color-mercury);
      border-left: 0;
      margin: 0;
      padding: 0;
      text-indent: -100000px;
      width: 30px;
    }

    &::after {
      color: var(--color-mercury);
      content: '\f002';
      font-family: var(--font-fontawesome);
      font-size: 18px;
      font-weight: 600;
      left: 6px;
      position: absolute;
      top: 3px;
      cursor: pointer;
    }
  }
}

.search-form {
  margin: 50px 0;

  .form-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-bottom: 50px;

    .form-type-search {
      align-items: center;
      display: flex;
      gap: 25px;
      margin: 0;

      label {
        color: var(--color-black);
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
        margin-bottom: 0;
        text-align: left;
        text-transform: initial;
      }

      .form-search {
        border: none;
        border-radius: 0;
        background: #f4f4f4;
        min-height: 45px;
        font-size: 16px;
        line-height: 18px;
      }
    }

    .form-actions {

      .form-submit {
        border: none;
        border-radius: 0;
        background: var(--color-feijoa);
        color: var(--color-white);
        padding: 13px 15px;
        flex-basis: calc(100% - 880px);
        font-size: 18px;
        line-height: 18px;
        margin: 0;
        width: 100%;
        display: inline-block;
        text-transform: uppercase;
      }
    }
  }

  .search-advanced {

    legend {

      .fieldset-legend {
        top: 20px;
      }
    }
  }
}

.search-results {
  list-style: none;
  padding: 0;

  li {
    border-top: 1px solid var(--color-silver);
    margin-bottom: 0;
    padding: 15px 0;

    h3 {
      font-size: 18px;
      margin-bottom: 0;

      a {
        font-size: 18px;
        font-weight: 900;
      }
    }

    &:last-child {
      border-bottom: 1px solid var(--color-silver);
    }
  }
}

@include lg {

  .block-search-form {

    .form-item {

      input {
        width: 160px;
      }
    }
  }

  .search-form {
    margin: 30px 0;

    .form-wrapper {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 30px;

      .form-type-search {
        flex-direction: column;
        gap: 20px;

        .form-search {
          width: 100%;
        }
      }
    }
  }
}

@include md {

  .block-search-form {

    .mobile-search-icon {
      cursor: pointer;
      display: block;
      line-height: inherit;
      padding-bottom: 15px;
    }

    .search-block-form {
      padding-bottom: 0;
      position: absolute;
      right: -300px;
      top: 100%;
      transition: 0.5s;
    }

    .search-open {

      .search-block-form {
        right: 0;
      }
    }
  }

  .logged-in {

    .block-search-form {

      .search-open {

        .search-block-form {
          right: -15px;
        }
      }
    }
  }
}
