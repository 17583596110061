// Import site utilities.
@import '../../global/utils/init';

.paragraphs-item-image-with-text {

  .content {
    align-items: stretch;
    display: flex;

    .field-name-field-description {
      align-items: center;
      background-color: var(--color-pacific-blue);
      display: flex;
      flex: 1;
      margin-bottom: 0;

      .field-items {
        padding: 50px 30px 50px 40px;
        
        p {
          color: var(--color-white);
          font-family: var(--font-avenir);
          font-size: 17px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 24px;
          margin-bottom: 0;
          opacity: 1;
          text-align: left;
        }
      }
    }

    .field-name-field-image {
      flex: 1;
      margin-bottom: 0;

      .field-items {
        height: 100%;

        .field-item {
          height: 100%;

          img {
            border: none;
            height: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@include md {

  .paragraphs-item-image-with-text {

    .content {
      flex-direction: column;
  
      .field-name-field-description {
  
        .field-items {
          padding: 20px;
        }
      }
    }
  }
}
