// Import site utilities.
@import '../../global/utils/init';

.front {

  .l-content {
    display: flex;
    gap: 40px;
  }
}

.front-page-block {
  background: var(--color-white) !important;
  box-shadow: 0 3px 12px var(--color-box-shadow);
  border-radius: 16px;
  flex-basis: 33% !important;
  position: relative;
  opacity: 1;
  margin-bottom: 0 !important;
  padding: 30px 15px !important;

  &.block-views--exp-proximity-test-page {

    .block-title {
      color: var(--color-sushi);
      // background-image: linear-gradient(to right, var(--color-sushi) 33%, rgba(255, 255, 255, 0) 0%);
      border-bottom: 5px dotted var(--color-sushi);
      background-position: bottom;
      background-size: 13px 4px;
      background-repeat: repeat-x;
    }
  }

  &.block-views-news-block {

    .block-title {
      // background-image: linear-gradient(to right, var(--color-pacific-blue) 33%, rgba(255, 255, 255, 0) 0%);
      border-bottom: 5px dotted var(--color-pacific-blue);
      background-position: bottom;
      background-size: 13px 4px;
      background-repeat: repeat-x;
      color: var(--color-pacific-blue);
    }
  }

  &.block-views-events-block-1 {

    .block-title {
      // background-image: linear-gradient(to right, var(--color-gray) 33%, rgba(255, 255, 255, 0) 0%);
      border-bottom: 5px dotted var(--color-gray);
      background-position: bottom;
      background-size: 13px 4px;
      background-repeat: repeat-x;
      color: var(--color-gray);
    }
  }

  .block-title {
    font-size: 38px;
    font-family: var(--font-archer);
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 27px;
    padding-bottom: 17px;
    text-transform: capitalize;
  }

  .block-content {

    .header-title {
      color: var(--color-black);
      font-size: 24px;
      font-weight: 900;
      line-height: 28px;
      margin-bottom: 16px !important;
      text-align: left;
      text-transform: uppercase;
    }

    form {

      .views-exposed-widgets {
        display: flex;
        flex-direction: column;
        margin: 0;
      }

      .views-exposed-widget {
        margin-bottom: 20px;

        label {
          font-size: 19px;
          font-weight: 600;
          font-family: var(--font-avenir);
          line-height: 26px;
        }

        .form-text {
          width: 100%;
        }

        select {
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          appearance: none;
          border: none;
          color: var(--color-black);
          font-family: var(--font-avenir);
          font-size: 17px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 23px;
          opacity: 1;
          padding: 0;
          text-align: center;
        }



        .form-checkboxes {

          .form-item {
            margin-bottom: 0;
            padding: 0;

            .option {
              font-size: 17px;
              font-weight: 400;
              line-height: 30px;
              margin: 0;
              margin-left: 5px;
              padding: 0;
            }
          }
        }

        .form-submit {
          background: var(--color-feijoa);
          border: none;
          border-radius: 0;
          color: var(--color-white);
          font-size: 21px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 28px;
          position: absolute;
          bottom: 45px;
          left: 0;
          right: 0;
          margin: 0 30px;
          padding: 16px 10px 12px;
        }
      }
    }

    .view-header {
      padding: 0;
      margin-bottom: 0;
    }

    .view-content {
      margin-bottom: 70px;
      border-top: none;

      .views-row {
        border-bottom: 1px solid var(--color-gray);
        font-size: 19px;
        padding: 20px 0 23px;
        text-align: left;

        .views-field {
          margin-bottom: 0;

          .date-display-single,
          .date-display-range {
            color: var(--color-black);
            font-size: 18px;
            line-height: 24px;
            font-weight: 900;
            font-family: var(--font-avenir);
            letter-spacing: 0;
            text-align: left;
          }

          a {
            color: var(--color-black);
            font-size: 17px;
            font-family: var(--font-avenir);
            font-weight: normal;
            line-height: 28px;
            text-decoration: underline;
          }
        }

        &:first-child {
          padding-top: 0;
        }
      }
    }

    .more-link {
      margin-top: 16px;
      position: absolute;
      bottom: 25px;
      left: 55%;

      a {
        color: var(--color-black) !important;
        font-size: 17px;
        font-family: var(--font-avenir);
        line-height: 28px;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}

@include xl {

  .front {

    .l-content {
      gap: 30px;
    }
  }

  .front-page-block {
    padding: 30px 10px !important;

    .block-title {
      font-size: 32px;
      line-height: 32px;
    }

    .block-content {

      form {

        .views-exposed-widget {

          .form-submit {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@include lg {

  .front {

    .l-content {
      flex-direction: column;
      gap: 25px;
    }
  }
}

@include md {

  .front-page-block {

    &.block-views--exp-facilities-page {

      .block-title {
        // border-bottom: 3px dashed var(--color-sushi);
        background-size: 12px 3px;
      }
    }
  
    &.block-views-news-block {
  
      .block-title {
        // border-bottom: 3px dashed var(--color-pacific-blue);
        background-size: 12px 3px;
      }
    }
  
    &.block-views-events-block-1 {
  
      .block-title {
        // border-bottom: 3px dashed var(--color-gray);
        background-size: 12px 3px;
      }
    }

    .block-title {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .block-content {

      .header-title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px !important;
      }

      form {

        .views-exposed-widgets {
          min-height: unset;
        }

        .views-exposed-widget {
          margin-bottom: 15px;

          label {
            font-size: 16px;
            line-height: 22px;
          }

          .form-checkboxes {

            .form-item {
  
              .option {
                font-size: 16px;
                line-height: 26px;
              }
            }
          }

          .form-submit {
            bottom: unset;
            left: unset;
            right: unset;
            margin: 0;
            position: relative;
            width: 100%;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.views-submit-button {
            text-align: center;
          }
        }
      }

      .view-content {
        margin-bottom: 20px;
  
        .views-row {
          font-size: 19px;
          padding: 15px 0;
  
          .views-field {
  
            a {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      .more-link {
        margin-top: 0;
        position: relative;
        bottom: unset;
        left: unset;
  
        a {
          font-size: 16px;
        }
      }
    }
  }
}
