// Import site utilities.
@import '../../global/utils/init';

/**
 * @file
 * Component Layout CSS and some Page layout overrides
 */

.layout {
  height: auto;

  .l-messages {
    margin: 0;
    padding: 0;

    .messages {
      margin: 30px 0;
    }
  }
}


.l-wrapper .block--inner-wrapper.container {
  margin-left: auto;
  margin-right: auto;
}

.layout--moscone {

  .l-wrapper {

    .l-wrapper-inner {
      max-width: unset;
      padding: 0;

      > .l-messages,
      > .l-page-title,
      > .tabs {
        clear: both;
        margin: 30px auto;
        max-width: 945px;
      }

      > .l-middle {
        clear: both;
        margin: 0 auto;
        max-width: 975px;

        .l-content {

          > .container {
            padding: 0;
          }
        }

        .l-sidebar {
          flex: 0 0 200px;

          .block-content {
            padding: 0;
          }
        }
      }
    }
  }
}

@include md {

  .layout--moscone {

    .l-wrapper {

      .l-wrapper-inner {

        .l-sidebar {

          .block-system-main-menu {
            display: none;
          }
        }
      }
    }
  }
}
