// Import site utilities.
@import '../../global/utils/init';

.webform-client-form {
  margin-top: 75px;
  position: relative;
  width: 80%;
    
  .form-required {
    border-bottom: none;
  }

  .webform-component--reference-staff,
  .webform-component--staff-email {
    display: none;
  }

  .name_field_wrapper {
    font-size: 18px;
    position: absolute;
    top: -74px;

    &::before {
      content: 'Contact form for: ';
      font-family: var(--font-avenir);
      font-size: 18px;
      line-height: 24px;
    }
  }

  .contact_field_wrapper {
    position: absolute;
    top: -38px;

    &::before {
      content: 'Phone Number: ';
      font-family: var(--font-avenir);
      font-size: 18px;
      line-height: 24px;
    }
  }

  .form-actions {

    .webform-submit {
      background: var(--color-feijoa);
      border: none;
      border-radius: 0;
      color: var(--color-white);
      font-size: 21px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 28px;
      padding: 16px 27px;
    }
  }
}

@include md {

  .webform-client-form {
    width: 100%;

    .form-actions {
      margin-bottom: 0;
    }
  }
}
