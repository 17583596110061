// Import site utilities.
@import '../../global/utils/init';

.view-staff {

  .views-row {
    border-top: 1px solid var(--color-silver);
    padding: 15px 0;

    &.last {
      border-bottom: 1px solid var(--color-silver);
    }
  }

  .views-field-title {

    h4 {
      font-size: 18px;
      line-height: 30px;
      margin: 0;
    }
  }

  .views-field-field-title {
    margin-bottom: 8px;
  }

  .views-field-body {
    margin-bottom: 15px;
    
    p {
      line-height: 30px;
    }
  }

  .contact-for {
    background-color: var(--color-line-item-bg);
    padding: 15px 20px;
    line-height: 30px;

    ul {
      margin-bottom: 0;

      li {
        margin-bottom: 0;
      }
    }
  }

  .email {
    padding-left: 20px;
  }

  .views-field-field-telephone {
    display: none;
  }

  .views-field-edit-node {
    margin-top: 22px;
  }
}

@include md {

  .view-staff {

    .views-row {
      padding-bottom: 0;
      margin-top: 30px;
  
      &.last {
        border-bottom: 0;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  
    .views-field-title {
  
      h4 {
        font-size: 17px;
        line-height: 24px;
      }
    }
  
    .email {
      padding-left: 20px;
    }
  
    .views-field-edit-node {
      margin-top: 15px;
    }
  }
}
