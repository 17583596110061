// Import site utilities.
@import '../../global/utils/init';

.front-page-block {

  &.provider-block-with-map {
    box-shadow: none;
    border-radius: 0;
    padding: 0 !important;

    .block-content {

      .views-exposed-widgets {
        margin: 0;
      }

      .view-content {
        margin: 0;
      }

      form {

        .views-submit-button {
          margin-bottom: 0;

          .form-submit {
            margin: 0;
            position: unset;
            width: 100%;
          }
        }
      }
    }
  }
}

.provider-block-with-map {
  margin-bottom: 45px !important;

  .block-title {
    font-size: 24px;
    font-family: var(--font-avenir);
    font-weight: 900;
    margin-bottom: 20px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: left;
    padding-bottom: 0;
  }

  .view-facilities,
  .filter-with-map {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    .leaflet-container {
      margin-top: -45px;
      width: 360px;
      z-index: 1;
    }
  }
}

.view-facilities,
.filter-with-map {

  .views-row {
    border-top: 1px solid var(--color-silver);
    padding: 15px 0;

    &.last {
      border-bottom: 1px solid var(--color-silver);
    }
    
    .views-field-title {

      h2 {
        color: var(--color-pacific-blue);
        font-size: 18px;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 5px;
      }
    }

    .views-field {
      line-height: 30px;
    }

    .views-field-field-in-center-hemodialysis,
    .views-field-field-home-hemodialysis,
    .views-field-field-home-peritoneal-dialysis,
    .views-field-field-pediatrics {
      display: none;
    }
  }
}

.views-exposed-widget {

  .form-type-geofield-proximity {
    margin: 0;
  }
}

#edit-field-geofield-distance {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;

  &::before {
    display: none;
  }

  .form-item-field-geofield-distance-origin {
    margin-top: 0;
    padding-top: 0;
  }

  .form-item-field-geofield-distance-distance {
    flex-basis: 150px;
    margin: 0;

    input {
      width: 100%;
    }
  }

  .form-item-field-geofield-distance-unit {
    flex-basis: 50px;
    margin: 0;

    select {
      width: 100%;
    }
  }

  .geofield-proximity-origin-from {
    margin: 0;
    padding: 0;
  }
}

.filter-with-map {

  .views-row {
    display: flex;
    flex-direction: column;

    .custom-services {
      order: 1;
    }

    .views-field-field-address-1 {
      order: 2;
    }
  }

  .leaflet-popup-content {
    margin: 15px 10px;
    font-size: 11px;
  }
}

#leaflet-map {

  .leaflet-left {
    left: 24px;
  }

  .leaflet-control-fullscreen {
    margin-top: 30px;
  }

  .leaflet-control-fullscreen-button {
    position: relative;

    span {
      color: var(--color-black);
      font-size: 14px;
      left: 36px;
      position: absolute;
      white-space: nowrap;
    }

    .use-text {
      left: -29px;
    }

    .bigger {
      display: block;
    }

    .smaller {
      display: none;
    }
  }

  &.leaflet-fullscreen-on {

    .leaflet-control-fullscreen-button {

      .bigger {
        display: none;
      }

      .smaller {
        display: block;
      }
    }
  }
}

@include md {

  .front-page-block {

    &.provider-block-with-map {
  
      .block-content {
  
        form {
  
          .views-submit-button {
            text-align: center;
          }
        }
      }
    }
  }

  .provider-block-with-map {
    margin-bottom: 20px !important;
  
    .block-title {
      font-size: 18px;
    }
  
    .view-facilities,
    .filter-with-map {
      grid-template-columns: 1fr;
      gap: 30px;
  
      .leaflet-container {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  .view-facilities,
  .filter-with-map {

    .views-row {
      padding: 15px 0;
      
      .views-field-title {
  
        h2 {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 0;
        }
      }
  
      .views-field {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
