// Import site utilities.
@import '../../global/utils/init';

.l-wrapper {

  .l-wrapper-inner {

    .l-sidebar {

      .block-system-main-menu {
        background: no-repeat;

        .block--inner-wrapper {
          padding: 0;

          .menu-tree {
            margin: 0;
            padding: 0;

            > li {
              display: none;
              padding: 0;

              > a {
                display: none;
              }

              ul {
                width: 100% !important;
              }

              > ul {
                background: no-repeat;
                border: 0;
                box-shadow: none;
                display: block !important;
                overflow: hidden;
                padding: 0;
                position: inherit;
                width: 100% !important;

                .menu-inner-wrapper {
                  min-height: unset;

                  li {
                    list-style-image: none;
                    list-style-type: none;
                    padding: 0;
                    width: 100%;

                    a {
                      display: flex;
                      font-size: 14px;
                      line-height: 15px;
                      margin-bottom: 10px;
                      max-width: 200px;
                      padding: 0;

                      &:hover {
                        text-decoration: none;
                      }
                    }

                    &.has-children {

                      > a {
                        align-items: center;
                        gap: 20px;
                        justify-content: space-between;
                        max-width: unset;

                        &::after {
                          content: '\f105';
                          display: block;
                          font-family: var(--font-fontawesome);
                          font-size: 22px;
                          padding-left: 5px;
                        }
                      }
                    }

                    &.open {

                      > a {
                        text-decoration: none;

                        &::after {
                          rotate: 90deg;
                        }
                      }
                    }

                    &.active-trail {

                      > a {
                        color: var(--color-pacific-blue);

                        &::after {
                          rotate: 90deg;
                        }
                      }

                      ul {
                        width: 100% !important;
                      }

                      > ul {
                        display: block !important;
                      }
                    }
                  }

                  > li {
                    padding: 0;
                    width: 100%;

                    > a {
                      font-weight: bold;

                      &::after {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }

              &:hover {
                background: no-repeat;
              }

              &.active-trail {
                background: no-repeat;
                display: block;

                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
