// Import site utilities.
@import '../../global/utils/init';

.l-header {
  background: var(--color-white);
  box-shadow: 0 3px 6px var(--color-box-shadow);
  left: 0;
  max-width: 100vw;
  padding: 20px 0 0;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 99;

  .l-header-inner {
    align-items: end;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    max-width: 1226px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .container {
      padding: 0;
    }

    .header-site-name-wrapper {
      min-height: unset;
      padding-bottom: 20px;
    }

    &::after,
    &::before {
      display: none;
    }

    .header-logo-wrapper {
      height: auto;
      margin: 0;
      max-height: unset;
      max-width: unset;

      .header-logo {
        height: auto;
        margin: 0;
        max-height: unset;
        max-width: unset;
        width: 252px;
      }
    }
  }

  .block-system-header {
    margin-top: auto;
    min-height: unset;

    .block--inner-wrapper {
      padding: 0;
    }

    .block-content {
      display: block;
    }
  }

  .block-system-main-menu {
    background: var(--color-white);
  }
}

.admin-bar {

  .l-header {
    top: 33px;
  }

  .scrolled {

    .l-header {
      top: 0;
    }
  }
}

@include lg {

  .l-header {

    .l-header-inner {

      .header-logo-wrapper {

        .header-logo {
          width: 180px;
        }
      }
    }
  }
}

@include md {

  .l-header {

    .menu-toggle-button {
      width: 26px;
      margin-left: 0;
      height: 27px;

      .menu-toggle-button-text {
        display: none;
      }
    }
    
    .l-header-inner {

      .header-site-name-wrapper {
        padding-bottom: 15px;
      }

      .header-logo-wrapper {

        .header-logo {
          width: 204px;
        }
      }

      .block-system-header {
        order: 2;
      }

      .block-system-main-menu {
        order: 1;
      }

      .block-search-form {
        order: 3;

        .mobile-search-icon {

          &::after {
            font-size: 28px;
          }
        }
      }
    }
  }
}
