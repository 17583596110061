// Import site utilities.
@import '../../global/utils/init';

.flexslider {
  margin: 0;

  .flex-direction-nav {

    li {
      margin: 0;
    }
  }

  .slides {

    img {
      max-height: 540px;
      object-fit: cover;
    }
  }
}

.front {

  .l-top {

    .block-views-hero-block {
      padding-top: 0;

      .view-hero {
        padding: 0;
      }

      .flexslider {
        border: 0;
      }
    }
  }
}

.hero-slider {

  .flex-direction-nav {

    a {

      &::before {
        font-size: 26px;
      }
    }
  }

  img {
    border: none;
    filter: none;
    margin: 0;
  }

  .slider-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .slider-left-wrapper {
    align-items: center;
    background-color: var(--color-pacific-blue);
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 50px;
    text-align: center;

    a {
      color: var(--color-white);
      font-family: var(--font-archer);
      font-size: 58px;
      line-height: 58px;
      text-decoration: none;
      text-align: left;

      &:active {
        background: transparent;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@include lg {

  .hero-slider {

    .slider-left-wrapper {
  
      a {
        font-size: 36px;
        line-height: 36px;
      }
    }
  }
}

@include md {

  .hero-slider {
    
    .slider-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }

    .slider-left-wrapper {
      padding: 45px 20px;
      min-height: 160px;
  
      a {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }

  .slider-right-wrapper {
    
    img {
      max-height: 220px;
      object-fit: cover;
    }
  }
}
