// Import site utilities.
@import '../../global/utils/init';

.events-page {

  .view-content {
    border-top: 1px solid var(--color-silver);

    .views-row {
      padding: 15px 0 13px;
      border-bottom: 1px solid var(--color-silver);

      .views-field-title {

        h2 {
          font-size: 18px;
          line-height: 30px;
          margin: 0;

          a {
            color: var(--color-pacific-blue);
            font-family: var(--font-avenir);
            font-weight: 900;
            margin-bottom: 0;
            text-align: left;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .views-field-field-date {

        .date-display-range,
        .date-display-single {
          font-size: 17px;
          line-height: 30px;
          font-family: var(--font-avenir);
        }
      }

      .views-field-body {
          
        p,
        h2,
        h3 {
          font-size: 18px !important;
          line-height: 30px;
          font-family: var(--font-avenir);
          margin-bottom: 0;

          strong,
          h2,
          h3,
          h4 {
            font-weight: 900;
          }
        }
      }

      .views-field-edit-node {
        margin-top: 10px;
      }
    }
  }
}

.node-type-event {

  .l-wrapper-inner {

    .node-event {

      .field-name-field-date {
        margin-bottom: 15px;
      }

      .paragraphs-item-pdf {

        iframe {
          aspect-ratio: unset;
          height: 1010px;
        }
      }
    }
  }
}
