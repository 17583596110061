// Import site utilities.
@import '../../global/utils/init';

h2.block-title,
.block-title {
  font-size: 48px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.front {

  .l-wrapper-inner {

    .block {
      padding: 0;
    }
  }
}

#views-exposed-form-facilities-page,
#views-exposed-form-facilities-block-1,
#views-exposed-form-proximity-test-block-1,
#views-exposed-form-proximity-test-page {

  .bef-checkboxes {

    .form-item-edit-field-in-center-hemodialysis-value-0,
    .form-item-edit-field-home-hemodialysis-value-0,
    .form-item-edit-field-home-peritoneal-dialysis-value-0,
    .form-item-edit-field-pediatrics-value-0,
    .form-item-edit-field-transplant-locations-value-0 {
      display: none;
    }
  }

  .views-widget-filter-field_in_center_hemodialysis_value,
  .views-widget-filter-field_in_center_hemodialysis_value_1,
  .views-widget-filter-field_home_hemodialysis_value,
  .views-widget-filter-field_home_peritoneal_dialysis_value,
  .views-widget-filter-type_1,
  .views-widget-filter-field_geofield_distance,
  .views-widget-filter-field_pediatrics_value {
    margin-bottom: 0;
  }
}
