// Import site utilities.
@import '../../global/utils/init';

.paragraphs-item-line-items {

  .line-item-with-bg {
    background-color: var(--color-line-item-bg);
    padding: 30px 24px;
  }

  .field-name-field-title {

    .field-item {
      font-size: 24px;
      font-weight: 900;
      line-height: 24px;
    }
  }

  .field-type-text-long {
      
    strong {
      font-weight: 900;
    }

    h1,
    h2 {
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    }

    h4 {
      color: var(--color-pacific-blue);
      font-weight: 900;
      font-family: var(--font-avenir);
      font-size: 17px;
      margin-bottom: 0;
      line-height: 24px;
    }

    > .field-items {

      > .field-item {
        border-top: 1px solid var(--color-silver);
        padding: 15px 0;

        &:last-child {
          border-bottom: 1px solid var(--color-silver);
        }
      }
    }
  }
}

@include md {

  .paragraphs-item-line-items {

    .line-item-with-bg {
      padding: 20px;
    }

    .field-name-field-title {

      .field-item {
        font-size: 22px;
      }
    }

    .field-type-text-long {
  
      > .field-items {
  
        > .field-item {
          padding: 12px 0;
        }
      }
    }
  }
}
