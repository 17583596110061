// Import site utilities.
@import '../../global/utils/init';

footer {

  .block-block-2 {

    .block--inner-wrapper {

      .social-media {
        display: flex;
        gap: 24px;
        margin: 0;
        padding-left: 10px;

        li {
          list-style: none;

          a {
            align-items: center;
            border-radius: 50%;
            border: 1px solid var(--color-white);
            color: var(--color-white);
            display: flex;
            font-size: 35px;
            padding-left: 10px;
            font-weight: 300;
            justify-content: center;
            height: 64px;
            text-decoration: none;
            width: 64px;
          }
        }
      }
    }
  }
}
