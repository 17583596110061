// Import site utilities.
@import '../../global/utils/init';

.l-footer {
  background: var(--color-feijoa);
  border: none;
  padding: 45px 0;

  .l-footer-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &.container::before {
      display: none;
    }
    
    .footer-message-block {
      color: #ff0000;
      grid-column: 2 / span 2;
      padding-top: 30px;
      line-height: 24px;

      .container {
        padding: 0;
      }
    }

    .footer-block {
      flex-basis: 285px;
      margin-right: 25px;

      p {
        color: var(--color-black);
        font-size: 17px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 0;

        strong {
          font-size: 18px;
          font-weight: 900;
        }

        a {
          color: var(--color-black);
        }
      }

      .container {
        padding: 0;
      }
    }
  }

  .block-block-facility-login {
    flex-basis: 285px;
    margin-right: 25px;

    .footer-login-button {

      h5 {
        color: var(--color-black);
        font-size: 18px;
        line-height: 24px;
      }

      p,
      ul,
      li {
        margin: 0;
        line-height: 24px;
      }

      ul {
        text-align: start;
      }

      li::marker {
        color: var(--color-pacific-blue);
      }
    }

    .block-content {

      a {
        color: var(--color-pacific-blue);
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .footer-logout-button {
      display: none;
    }
  }

  .block-on-the-web-social-links {
    margin-left: auto;

    .container {
      padding: 0;
    }

    .block-content {
      display: flex;
      gap: 45px;

      a {
        align-items: center;
        border-radius: 50%;
        border: 1px solid var(--color-white);
        color: var(--color-white);
        display: flex;
        justify-content: center;
        height: 64px;
        width: 64px;

        &:hover {
          text-decoration: none;
        }

        i {
          font-size: 35px;
          font-weight: 300;
        }
      }
    }
  }
}

.logged-in {

  .l-footer {

    .block-block-facility-login {

      .footer-login-button {
        display: none;
      }

      .footer-logout-button {
        display: block;
      }
    }
  }
}

//Tabs
.user-authentication-page {

  .l-wrapper-inner {

    .container {
      max-width: 630px;

      .tabs {
        background: var(--color-pacific-blue);
        border: none;
        max-width: 578px;
  
        ul {
          padding: 0;
  
          li {
  
            a {
              background: var(--color-pacific-blue);
              border-radius: 0;
              color: var(--color-white);
              padding: 16px 20px 10px;
  
              &.active {
                background: var(--color-mercury) !important;
                color: var(--color-black);
              }
            }
          }
        }
      }
    }
  }
}

@include lg {

  .l-footer {

    .block-on-the-web-social-links {

      .block-content {
        gap: 25px;
      }
    }
  }
}

@include md {

  .l-footer {
    padding: 10px 0;
  
    .l-footer-inner {
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      text-align: center;
  
      .footer-message-block {
        grid-column: 1;
        padding: 10px;
      }

      .footer-address {
        padding-top: 20px;
      }
    }

    .block-on-the-web-social-links {
      margin-left: unset;

      .block-content {
        gap: 20px;
  
        a {
          height: 47px;
          width: 47px;

          i {
            font-size: 25px;
          }
        }
      }
    }

    .block-block-facility-login {
      flex-basis: 100%;
    }
  }
}
