// Import site utilities.
@import '../../global/utils/init';

.paragraphs-item-text-block {

  p:has(img) {
    text-align: center;
  }

  h1,
  h2 {
    font-size: 18px;
    line-height: 28px;
  }
}
