// Import site utilities.
@import '../../global/utils/init';

.news-page {

  .views-row {
    border-top: 1px solid var(--color-silver);
    padding: 15px 0;

    &.last {
      border-bottom: 1px solid var(--color-silver);
    }
  }

  .views-field-title {

    a {
      font-size: 18px;
      font-weight: 900;
      line-height: 30px;
    }
  }

  .views-field-body {
    font-size: 17px;
    line-height: 30px;

    p {
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }

    h1,
    h2 {
      font-size: 18px;
      line-height: 28px;
    }

    img {
      display: none;
    }
  }
}

@include md {

  .news-page {

    .views-row {
      padding: 30px 0;
    }
  
    .views-field-title {
  
      a {
        font-size: 17px;
        line-height: 24px;
      }
    }
  
    .views-field-body {
      font-size: 16px;
      line-height: 24px;
  
      h1,
      h2 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
