// Import site utilities.
@import '../../global/utils/init';

.jquery-modal {

  &.blocker {
    background-color: transparent;
  }

  .modal {
    border-radius: 0;
    border: 1px solid var(--color-mercury);
    box-shadow: 6px 6px 12px var(--color-box-shadow);
    height: auto;
    max-width: 1100px;
    width: auto;
    padding: 50px 50px 30px;

    a {

      &.close-modal {
        display: none;
      }
    }

    .field-name-field-graphics-image {
      text-align: center;
    }

    img {
      max-height: 100%;
    }

    .image_wrapper {
      margin-bottom: 35px;
    }

    .icon-btn-wrapper {
      color: var(--color-black);
      display: flex;
      max-width: 150px;
    }

    .close-btn {
      font-weight: 200;
      text-decoration: underline;
    }

    .cross-icon {

      &::after {
        display: inline-block;
        content: '\00d7';
        font-size: 40px;
        margin-right: 10px;
        height: 23px;
      }
    }
  }
}

.paragraphs-item-graphic {

  .btn-wrapper {
    text-align: center;
  }

  .open-btn {
    color: var(--color-black);
    font-weight: 200;
    text-decoration: underline;
  }

  .field-name-field-graphics-image {
    margin-bottom: 10px;
    text-align: center;
  }
}

@include md {

  .jquery-modal {

    &.blocker {
      background-color: transparent;
    }
  
    .modal {
      padding: 20px;
  
      .image_wrapper {
        margin-bottom: 20px;
      }
  
      .cross-icon {
  
        &::after {
          font-size: 30px;
        }
      }
    }
  }
}
